var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: "btn",
      staticClass: "ant-btn",
      class: _vm.type,
      attrs: { type: "button", disabled: _vm.disabled },
      on: { click: _vm.handleClick },
    },
    [
      _vm.type === "iconBtn" && _vm.icon
        ? _c("svg", { staticClass: "iconpark-icon" }, [
            _c("use", { attrs: { href: "#" + _vm.icon } }),
          ])
        : _vm._e(),
      _vm.type === "iconBtn" && _vm.anticon
        ? _c("a-icon", { attrs: { type: _vm.anticon } })
        : _c(
            "div",
            { staticClass: "btn-content" },
            [
              _vm.prefix
                ? _c("svg", { staticClass: "iconpark-icon prefix" }, [
                    _c("use", { attrs: { href: "#" + _vm.prefix } }),
                  ])
                : _vm._e(),
              _vm.antprefix
                ? _c("a-icon", {
                    staticClass: "prefix",
                    attrs: { type: _vm.antprefix },
                  })
                : _vm._e(),
              _vm._t("default"),
              _vm.suffix
                ? _c("svg", { staticClass: "iconpark-icon suffix" }, [
                    _c("use", { attrs: { href: "#" + _vm.suffix } }),
                  ])
                : _vm._e(),
              _vm.antsuffix
                ? _c("a-icon", {
                    staticClass: "suffix",
                    attrs: { type: _vm.antsuffix },
                  })
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }