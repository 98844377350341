import { LOGIN } from '../api'
import { METHOD, request } from '@/utils/request'

// 租户激活获取验证码
export async function getActivateCode(params) {
  return request(`${LOGIN}/register/getActivateCode`, METHOD.GET, params)
}

// 商户激活
export async function activateTenant(params) {
  return request(`${LOGIN}/register/activateTenant`, METHOD.POST, params)
}

// 设置密码
export async function configPassword(params) {
  return request(`${LOGIN}/login/configPassword`, METHOD.POST, params)
}

// 补充租户信息
export async function perfectTenant(params) {
  return request(`${LOGIN}/register/perfectTenant`, METHOD.POST, params)
}

// 登陆认证
export async function login(params) {
  return request(`${LOGIN}/login/doLoginByPasswordAndCode`, METHOD.POST, params)
}

// 找回密码-获取验证码
export async function passwordGetActivateCode(params) {
  return request(`${LOGIN}/retrieve/getRetrieveCode`, METHOD.GET, params)
}

// 找回密码-验证验证码
export async function doValidateCode(params) {
  return request(`${LOGIN}/retrieve/doValidateCode`, METHOD.POST, params)
}

// 找回密码-密码修改
export async function resetPassword(params) {
  return request(`${LOGIN}/retrieve/doRetrievePassword`, METHOD.POST, params)
}

// 登出
export async function doLogout(params) {
  return request(`${LOGIN}/login/doLogout`, METHOD.GET, params)
}

// 验证码登陆-获取验证码
export async function getLoginCode(params) {
  return request(`${LOGIN}/login/getLoginCode`, METHOD.GET, params)
}
// 验证码登陆
export async function doCodeLogin(params) {
  return request(`${LOGIN}/login/doCodeLogin`, METHOD.POST, params)
}

// 登录成功-租户列表【需要login-token】
export async function getUserTenantList(params) {
  return request(`${LOGIN}/login/getUserTenantList`, METHOD.GET, params)
}

// 登录成功-进入租户系统【需要token】
export async function loginTenant(params) {
  return request(`${LOGIN}/login/loginTenant`, METHOD.GET, params)
}

// 租户-设置/修改租户信息【需要token】
export async function updateTenant(params) {
  return request(`${LOGIN}/tenant/updateTenant`, METHOD.POST, params)
}

// 租户-设置/修改租户信息【需要token】
export async function changePassword(params) {
  return request(`${LOGIN}/login/changePassword`, METHOD.POST, params)
}

// 租户-注册
export async function tenantRegister(params) {
  return request(`${LOGIN}/tenant/register`, METHOD.POST, params)
}

// 租户信息
export async function getTenant(params) {
  return request(`${LOGIN}/tenant/getTenant`, METHOD.GET, params)
}

// 获取图标库资源URL
export async function getIconUrl(params) {
  return request(`${LOGIN}/func/getIconUrl`, METHOD.GET, params)
}

// 生成滑块验证内容
export async function getImagesApi(params) {
  return request(`${LOGIN}/slider/getSliderValidation`, METHOD.GET, params)
}

// 滑块验证
export async function checkImagesApi(params) {
  return request(`${LOGIN}/slider/checkSliderValidation`, METHOD.POST, params)
}

// 请求公钥
export async function getPublicKeyApi(params) {
  return request(`${LOGIN}/encryption/queryPublicKey`, METHOD.GET, params)
}

// 请求公钥
export async function getWrongPassWordTimes(params) {
  return request(`${LOGIN}/login/getWrongPassWordTimes`, METHOD.GET, params)
}
