var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "create-approval" }, [
    _c("div", { staticClass: "create-approval-main" }, [
      _c("div", { staticClass: "form-design" }, [
        _c("div", { staticClass: "form-design-middle" }, [
          _c("div", { staticClass: "form-design-middle-inner" }, [
            _c("div", { staticClass: "phone-wrapper" }, [
              _c("div", { staticClass: "phone-wrapper-screen" }, [
                _c("div", { staticClass: "field-list" }, [
                  _c("div", { staticClass: "field-list-header" }, [
                    _vm._v(_vm._s(_vm.flowSetting.flowName || "未命名审批")),
                  ]),
                  _c("div", { staticClass: "field-list-drop-area" }, [
                    _c("div", { staticClass: "field-list-empty" }, [
                      _c("div", { staticClass: "field-list-empty-inner" }, [
                        _c(
                          "div",
                          { staticClass: "field-list-tip" },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.dataListLength === 0,
                                    expression: "dataListLength === 0",
                                  },
                                ],
                                staticClass: "field-list-tip-info",
                              },
                              [_vm._v("选择左侧控件，拖动到此处")]
                            ),
                            [_vm._t("default")],
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }