var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-time-picker", {
    style: "width:" + _vm.record.options.width,
    attrs: {
      disabled: _vm.record.options.disabled || _vm.parentDisabled,
      "allow-clear": _vm.record.options.clearable,
      placeholder: _vm.record.options.placeholder,
      format: _vm.record.options.format,
      "disabled-hours": _vm.disabledHours,
      "disabled-minutes": _vm.disabledMinutes,
      "disabled-seconds": _vm.disabledSeconds,
      value: _vm.time,
    },
    on: { change: _vm.handleSelectChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }