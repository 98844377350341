var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "autocompute",
      class: {
        "wkflow-error-tip":
          !!_vm.record.options.baseInfoList[0].labelErrorMsg ||
          _vm.record.hasErrorValid,
      },
    },
    [
      _vm._l(_vm.record.options.baseInfoList, function (item, index) {
        return [
          _c(
            "div",
            { key: "baseInfoList" + index, staticClass: "baseInfoItemBox" },
            [
              _c(
                "div",
                {
                  staticClass: "label",
                  class: { require: _vm.record.isRequire },
                },
                [_vm._v(_vm._s(item.label))]
              ),
              _vm._m(0, true),
            ]
          ),
        ]
      }),
      _c(
        "a-modal",
        {
          attrs: { title: "计算公式" },
          on: { ok: _vm.handleOk },
          model: {
            value: _vm.record.options.visible,
            callback: function ($$v) {
              _vm.$set(_vm.record.options, "visible", $$v)
            },
            expression: "record.options.visible",
          },
        },
        [
          _c("div", { staticClass: "autocompute-form-p" }, [
            _c("div", [_vm._v("计算公式：")]),
            _c(
              "div",
              {
                staticClass: "formula-p",
                style: { borderColor: _vm.formulaErr ? "red" : "#d8d8d8" },
              },
              [
                _c(
                  "div",
                  { staticClass: "formula-content" },
                  [
                    _c("span", [_vm._v("计算公式=")]),
                    _vm.exps.length > 0
                      ? _vm._l(_vm.exps, function (item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "formula-item",
                              style: {
                                color: _vm.validArg(item) ? "inherit" : "red",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("matchArg")(item, _vm.that)) +
                                  " "
                              ),
                            ]
                          )
                        })
                      : [
                          _c(
                            "span",
                            { staticClass: "formula-content-placeholder" },
                            [_vm._v(" 请在下方选择计算对象和计算符号完成编辑 ")]
                          ),
                        ],
                  ],
                  2
                ),
                _c("div", { staticClass: "formula-operations" }, [
                  _c(
                    "div",
                    { staticClass: "opt-del", on: { click: _vm.delExp } },
                    [_vm._v("删除")]
                  ),
                  _c(
                    "div",
                    { staticClass: "opt-clear", on: { click: _vm.clearExp } },
                    [_vm._v("清空")]
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formulaErr,
                    expression: "formulaErr",
                  },
                ],
              },
              [
                _c("a-alert", {
                  attrs: {
                    message: "编辑的计算公式为空或不符合计算法则，无法计算",
                    type: "error",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v(" 仅数字、金额控件可用于计算 "),
                    ]),
                    _c("a-icon", {
                      attrs: { type: "info-circle", theme: "filled" },
                    }),
                  ],
                  2
                ),
                _vm._v(" 控件："),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "autocompute-comp" },
                  _vm._l(_vm.argsList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "autocompute-comp-key",
                        on: {
                          click: function ($event) {
                            return _vm.addArg(item.model)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _c("div", [
              _vm._v(" 数字："),
              _c("br"),
              _c(
                "div",
                { staticClass: "autocompute-nums" },
                _vm._l(_vm.numbers, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "autocompute-nums-key",
                      on: {
                        click: function ($event) {
                          return _vm.addOption(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("div", [
              _vm._v(" 符号："),
              _c("br"),
              _c(
                "div",
                { staticClass: "autocompute-symbol" },
                _vm._l(_vm.symbols, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "autocompute-symbol-key",
                      on: {
                        click: function ($event) {
                          return _vm.addOption(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "value" }, [_vm._v("自动计算")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }