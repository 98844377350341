var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PerTreeSelect", {
    attrs: {
      "mode-type": _vm.record.options.modeType,
      placeholder: _vm.record.options.placeholder,
      disabled: _vm.record.options.disabled,
    },
    on: { getPerTreeValue: _vm.handleChange },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v
      },
      expression: "data",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }