var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-panel" },
    [
      _vm.type == "kForm"
        ? _c(
            "div",
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.list.length === 0,
                      expression: "data.list.length === 0",
                    },
                  ],
                  staticClass: "hint-text",
                },
                [
                  _c("a-empty", {
                    attrs: { description: "从左侧选择控件添加" },
                  }),
                ],
                1
              ),
              _c(
                "a-form",
                {
                  staticClass: "a-form-box k-form-build",
                  style: _vm.data.config.customStyle,
                  attrs: {
                    form: _vm.form,
                    layout: _vm.data.config.layout,
                    "hide-required-mark": _vm.data.config.hideRequiredMark,
                  },
                },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "draggable-box",
                        attrs: { tag: "div" },
                        on: {
                          add: _vm.deepClone,
                          start: function ($event) {
                            return _vm.dragStart($event, _vm.data.list)
                          },
                        },
                        model: {
                          value: _vm.data.list,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "list", $$v)
                          },
                          expression: "data.list",
                        },
                      },
                      "draggable",
                      {
                        group: "form-draggable",
                        ghostClass: "moving",
                        animation: 180,
                        handle: ".drag-move",
                      },
                      false
                    ),
                    [
                      _c(
                        "transition-group",
                        {
                          staticClass: "list-main",
                          attrs: { tag: "div", name: "list" },
                        },
                        _vm._l(_vm.data.list, function (record) {
                          return _c("layoutItem", {
                            key: record.key,
                            staticClass: "drag-move",
                            attrs: {
                              record: record,
                              config: _vm.data.config,
                              "select-item": _vm.selectItem,
                              "start-type": _vm.startType,
                              "insert-allowed-type": _vm.insertAllowedType,
                              "hide-model": _vm.hideModel,
                            },
                            on: {
                              "update:selectItem": function ($event) {
                                _vm.selectItem = $event
                              },
                              "update:select-item": function ($event) {
                                _vm.selectItem = $event
                              },
                              dragStart: _vm.dragStart,
                              handleSelectItem: _vm.handleSelectItem,
                              handleCopy: _vm.handleCopy,
                              handleDelete: _vm.handleDelete,
                              handleColAdd: _vm.handleColAdd,
                              handleShowRightMenu: _vm.handleShowRightMenu,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "phonePanl",
            {
              staticClass: "phone-panel",
              attrs: {
                "flow-setting": _vm.flowSetting,
                "data-list-length": _vm.data.list.length,
              },
            },
            [
              _c(
                "a-form",
                {
                  staticClass: "a-form-box k-form-build",
                  style: _vm.data.config.customStyle,
                  attrs: {
                    form: _vm.form,
                    layout: _vm.data.config.layout,
                    "hide-required-mark": _vm.data.config.hideRequiredMark,
                  },
                },
                [
                  _c(
                    "draggable",
                    _vm._b(
                      {
                        staticClass: "draggable-box",
                        attrs: { tag: "div" },
                        on: {
                          add: _vm.deepClone,
                          start: function ($event) {
                            return _vm.dragStart($event, _vm.data.list)
                          },
                        },
                        model: {
                          value: _vm.data.list,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "list", $$v)
                          },
                          expression: "data.list",
                        },
                      },
                      "draggable",
                      {
                        group: "form-draggable",
                        ghostClass: "moving",
                        animation: 180,
                        handle: ".drag-move",
                      },
                      false
                    ),
                    [
                      _c(
                        "transition-group",
                        {
                          staticClass: "list-main",
                          attrs: { tag: "div", name: "list" },
                        },
                        _vm._l(_vm.data.list, function (record) {
                          return _c("layoutItem", {
                            key: record.key,
                            staticClass: "drag-move",
                            attrs: {
                              record: record,
                              type: _vm.type,
                              config: _vm.data.config,
                              "select-item": _vm.selectItem,
                              "start-type": _vm.startType,
                              "insert-allowed-type": _vm.insertAllowedType,
                              "hide-model": _vm.hideModel,
                            },
                            on: {
                              "update:selectItem": function ($event) {
                                _vm.selectItem = $event
                              },
                              "update:select-item": function ($event) {
                                _vm.selectItem = $event
                              },
                              dragStart: _vm.dragStart,
                              handleSelectItem: _vm.handleSelectItem,
                              handleCopy: _vm.handleCopy,
                              handleDelete: _vm.handleDelete,
                              handleColAdd: _vm.handleColAdd,
                              handleShowRightMenu: _vm.handleShowRightMenu,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showRightMenu,
              expression: "showRightMenu",
            },
          ],
          staticClass: "right-menu",
          style: { top: _vm.menuTop + "px", left: _vm.menuLeft + "px" },
        },
        [
          _c("ul", [
            _c(
              "li",
              { on: { click: _vm.handleDownMerge } },
              [
                _c("a-icon", { attrs: { type: "caret-down" } }),
                _vm._v("向下合并"),
              ],
              1
            ),
            _c(
              "li",
              { on: { click: _vm.handleRightMerge } },
              [
                _c("a-icon", { attrs: { type: "caret-right" } }),
                _vm._v("向右合并"),
              ],
              1
            ),
            _c(
              "li",
              { on: { click: _vm.handleRightSplit } },
              [
                _c("a-icon", { attrs: { type: "border-inner" } }),
                _vm._v("拆分单元格"),
              ],
              1
            ),
            _c(
              "li",
              { on: { click: _vm.handleAddCol } },
              [
                _c("a-icon", { attrs: { type: "border-horizontal" } }),
                _vm._v("增加一列"),
              ],
              1
            ),
            _c(
              "li",
              { on: { click: _vm.handleAddRow } },
              [
                _c("a-icon", { attrs: { type: "border-verticle" } }),
                _vm._v("增加一行"),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }