var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "select-icon" }, [
        _vm.isDev && _vm.type
          ? _c("img", {
              attrs: { src: "" + _vm.basePath + _vm.type + ".png", alt: "" },
            })
          : !_vm.isDev && _vm.type
          ? _c("img", {
              attrs: { src: "" + _vm.basePath + _vm.type + ".png", alt: "" },
            })
          : _vm._e(),
      ]),
      _c(
        "a-popover",
        {
          attrs: {
            trigger: "click",
            placement: "bottom",
            "auto-adjust-overflow": false,
          },
          model: {
            value: _vm.iconVisible,
            callback: function ($$v) {
              _vm.iconVisible = $$v
            },
            expression: "iconVisible",
          },
        },
        [
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "a-space",
                [
                  _vm._l(_vm.iconList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "icon" + index,
                        staticClass: "icon",
                        on: {
                          click: function ($event) {
                            return _vm.selectIcon(item.type)
                          },
                        },
                      },
                      [
                        _vm.isDev
                          ? _c("img", {
                              attrs: {
                                src: "" + _vm.basePath + item.type + ".png",
                                alt: "",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: "" + _vm.basePath + item.type + ".png",
                                alt: "",
                              },
                            }),
                        _vm.isDev
                          ? _c("span", [_vm._v(_vm._s(item.name))])
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("a-button", { staticClass: "a-but", attrs: { type: "link" } }, [
            _vm._v("更改图标"),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }