var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "properties-centent kk-checkbox" }, [
    _c(
      "div",
      { staticClass: "properties-body" },
      [
        _c(
          "a-form",
          [
            _c(
              "a-form-item",
              { attrs: { label: "表单布局" } },
              [
                _c(
                  "a-radio-group",
                  {
                    attrs: { "button-style": "solid" },
                    model: {
                      value: _vm.config.layout,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "layout", $$v)
                      },
                      expression: "config.layout",
                    },
                  },
                  [
                    _c("a-radio-button", { attrs: { value: "horizontal" } }, [
                      _vm._v("水平"),
                    ]),
                    _c("a-radio-button", { attrs: { value: "vertical" } }, [
                      _vm._v("垂直"),
                    ]),
                    _c("a-radio-button", { attrs: { value: "inline" } }, [
                      _vm._v("行内"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-form-item",
              { attrs: { label: "标签布局（水平布局生效）" } },
              [
                _c(
                  "a-radio-group",
                  {
                    attrs: { "button-style": "solid" },
                    model: {
                      value: _vm.config.labelLayout,
                      callback: function ($$v) {
                        _vm.$set(_vm.config, "labelLayout", $$v)
                      },
                      expression: "config.labelLayout",
                    },
                  },
                  [
                    _c("a-radio-button", { attrs: { value: "flex" } }, [
                      _vm._v("固定"),
                    ]),
                    _c("a-radio-button", { attrs: { value: "Grid" } }, [
                      _vm._v("栅格"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.config.labelLayout === "flex",
                    expression: "config.labelLayout === 'flex'",
                  },
                ],
                attrs: { label: "标签宽度（px）" },
              },
              [
                _c("a-input-number", {
                  model: {
                    value: _vm.config.labelWidth,
                    callback: function ($$v) {
                      _vm.$set(_vm.config, "labelWidth", $$v)
                    },
                    expression: "config.labelWidth",
                  },
                }),
              ],
              1
            ),
            _c(
              "a-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.config.labelLayout !== "flex",
                    expression: "config.labelLayout !== 'flex'",
                  },
                ],
                attrs: { label: "labelCol" },
              },
              [
                _c(
                  "div",
                  { staticClass: "change-col-box" },
                  [
                    _c("a-slider", {
                      attrs: { id: "test", max: 24, min: 0 },
                      on: { change: _vm.handleChangeCol },
                      model: {
                        value: _vm.config.labelCol.xs,
                        callback: function ($$v) {
                          _vm.$set(_vm.config.labelCol, "xs", $$v)
                        },
                        expression: "config.labelCol.xs",
                      },
                    }),
                    _c(
                      "div",
                      [
                        _c("label", [_vm._v("xs:")]),
                        _c("a-input-number", {
                          model: {
                            value: _vm.config.labelCol.xs,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.labelCol, "xs", $$v)
                            },
                            expression: "config.labelCol.xs",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("label", [_vm._v("sm:")]),
                        _c("a-input-number", {
                          model: {
                            value: _vm.config.labelCol.sm,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.labelCol, "sm", $$v)
                            },
                            expression: "config.labelCol.sm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("label", [_vm._v("md:")]),
                        _c("a-input-number", {
                          model: {
                            value: _vm.config.labelCol.md,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.labelCol, "md", $$v)
                            },
                            expression: "config.labelCol.md",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("label", [_vm._v("lg:")]),
                        _c("a-input-number", {
                          model: {
                            value: _vm.config.labelCol.lg,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.labelCol, "lg", $$v)
                            },
                            expression: "config.labelCol.lg",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("label", [_vm._v("xl:")]),
                        _c("a-input-number", {
                          model: {
                            value: _vm.config.labelCol.xl,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.labelCol, "xl", $$v)
                            },
                            expression: "config.labelCol.xl",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("label", [_vm._v("xxl:")]),
                        _c("a-input-number", {
                          model: {
                            value: _vm.config.labelCol.xxl,
                            callback: function ($$v) {
                              _vm.$set(_vm.config.labelCol, "xxl", $$v)
                            },
                            expression: "config.labelCol.xxl",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "a-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.config.labelLayout !== "flex",
                    expression: "config.labelLayout !== 'flex'",
                  },
                ],
                attrs: { label: "wrapperCol" },
              },
              [
                _c("div", { staticClass: "change-col-box" }, [
                  _c(
                    "div",
                    [
                      _c("label", [_vm._v("xs:")]),
                      _c("a-input-number", {
                        model: {
                          value: _vm.config.wrapperCol.xs,
                          callback: function ($$v) {
                            _vm.$set(_vm.config.wrapperCol, "xs", $$v)
                          },
                          expression: "config.wrapperCol.xs",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("label", [_vm._v("sm:")]),
                      _c("a-input-number", {
                        model: {
                          value: _vm.config.wrapperCol.sm,
                          callback: function ($$v) {
                            _vm.$set(_vm.config.wrapperCol, "sm", $$v)
                          },
                          expression: "config.wrapperCol.sm",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("label", [_vm._v("md:")]),
                      _c("a-input-number", {
                        model: {
                          value: _vm.config.wrapperCol.md,
                          callback: function ($$v) {
                            _vm.$set(_vm.config.wrapperCol, "md", $$v)
                          },
                          expression: "config.wrapperCol.md",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("label", [_vm._v("lg:")]),
                      _c("a-input-number", {
                        model: {
                          value: _vm.config.wrapperCol.lg,
                          callback: function ($$v) {
                            _vm.$set(_vm.config.wrapperCol, "lg", $$v)
                          },
                          expression: "config.wrapperCol.lg",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("label", [_vm._v("xl:")]),
                      _c("a-input-number", {
                        model: {
                          value: _vm.config.wrapperCol.xl,
                          callback: function ($$v) {
                            _vm.$set(_vm.config.wrapperCol, "xl", $$v)
                          },
                          expression: "config.wrapperCol.xl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("label", [_vm._v("xxl:")]),
                      _c("a-input-number", {
                        model: {
                          value: _vm.config.wrapperCol.xxl,
                          callback: function ($$v) {
                            _vm.$set(_vm.config.wrapperCol, "xxl", $$v)
                          },
                          expression: "config.wrapperCol.xxl",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "a-form-item",
              { attrs: { label: "预览模态框宽度" } },
              [
                _c("a-input-number", {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.previewOptions.width,
                    callback: function ($$v) {
                      _vm.$set(_vm.previewOptions, "width", $$v)
                    },
                    expression: "previewOptions.width",
                  },
                }),
              ],
              1
            ),
            _c(
              "a-form-item",
              { attrs: { label: "表单CSS" } },
              [
                _c("a-textarea", {
                  model: {
                    value: _vm.config.customStyle,
                    callback: function ($$v) {
                      _vm.$set(_vm.config, "customStyle", $$v)
                    },
                    expression: "config.customStyle",
                  },
                }),
              ],
              1
            ),
            _c(
              "a-form-item",
              { attrs: { label: "表单属性" } },
              [
                _c("kCheckbox", {
                  attrs: { label: "隐藏必选标记" },
                  model: {
                    value: _vm.config.hideRequiredMark,
                    callback: function ($$v) {
                      _vm.$set(_vm.config, "hideRequiredMark", $$v)
                    },
                    expression: "config.hideRequiredMark",
                  },
                }),
              ],
              1
            ),
            _c("a-form-item", { attrs: { label: "提示" } }, [
              _vm._v(" 实际预览效果请点击预览查看 "),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }