import Cookie from 'js-cookie'
import { formatFileName, downloadFile } from '@/utils/file-reader.js'
// import { Loading } from 'element-ui'
import Loading from '@/utils/loading'
import { makePostSign, makeGetSign } from '@/utils/sign.js'

const url = process.env.VUE_APP_API_BASE_URL
url.replace('/api', '')
// 无操作的记录的时间(单位秒)  超过半小时自动退出
let noOperationTime = 0
let computedTimeInterval = null
function computedTime() {
  const token = Cookie.get('hrsaas-token')
  if (token) {
    if (!computedTimeInterval) {
      computedTimeInterval = setInterval(() => {
        noOperationTime += 1
        if (noOperationTime >= 30 * 60) {
          location.href = `${url}/login`
        }
      }, 1000)
    }
  } else {
    noOperationTime = 0
    clearInterval(computedTimeInterval)
    computedTimeInterval = null
  }
}

// 请求加 loading
let loadingHideTimeOut // 定义loading变量
let needLoadingRequestCount = 0
export function initRequest(router) {
  router.beforeEach((to, from, next) => {
    // 若当前还有loading 则关闭
    endLoading()
    // 归为初始化状态
    needLoadingRequestCount = 0
    clearTimeout(loadingHideTimeOut)
    next()
  })
}

function startLoading() {
  // 使用Element loading-start 方法
  Loading.service({
    text: '正在为你努力加载中...',
    background: 'rgba(255, 255, 255, 0.4)'
  })
}

function endLoading() {
  // 使用Element loading-close 方法
  Loading.close()
}
// 那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
// 声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
// 调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    // 如果页面跳转前线请求了接口再跳转，且跳转页面初始化请求了接口，这个地方执行顺序出错，页面loading不展示
    loadingHideTimeOut = setTimeout(() => {
      // 当执行的时候查看是否有新的请求发起，如果有则中断
      if (needLoadingRequestCount !== 0) return
      endLoading()
    }, 20)
  }
}
// loading结束

// 401拦截
const resp401 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(response, options) {
    const { message } = options
    if (response.code === 401) {
      message.error('无此权限')
    }
    return response
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options
    const { response } = error
    if (response.status === 401) {
      message.error('无此权限')
    }
    return Promise.reject(error)
  }
}

const resp403 = {
  onFulfilled(response, options) {
    const { message } = options
    if (response.code === 403) {
      message.error('请求被拒绝')
    }
    return response
  },
  onRejected(error, options) {
    const { message } = options
    const { response } = error
    if (response.status === 403) {
      message.error('请求被拒绝')
    }
    return Promise.reject(error)
  }
}

// 响应拦截
const respCommon = {
  onFulfilled(response, options) {
    const { message } = options
    if (response.status - 0 === 200) {
      if (response.data.code - 0 === 100 || response.data.code === 0) {
        return response.data
      } else if (response.config.responseType === 'blob') {
        if (!response.config.headers.isDownload) {
          // 其他的不需要下载的返回文件流的情况
          return response.data
        } else {
          // 文件流下载
          const fileName = formatFileName(response.headers)
          downloadFile(response.data, fileName)
          return response
        }
      } else if (response.data.code === 401000) {
        message.error('登陆过期，请重新登录')
        location.href = `${url}/login`
      } else if (response.data.code === 403019) {
        // 校验纯色图片后上传的图片为纯色的返回的处理
        return Promise.reject({
          code: response.data.code,
          data: '',
          msg: response.data.message
          // 处理异常 不用在页面上trycatch 控制台也不会报错
        })
      } else {
        message.error(response.data.message)
        return Promise.reject({
          code: response.data.code,
          data: '',
          msg: response.data.message
          // 处理异常 不用在页面上trycatch 控制台也不会报错
        })
      }
    } else {
      message.error('请求被拒绝')
      return Promise.reject({
        code: response.data.code,
        data: '',
        msg: response.data.message
      })
    }
  },
  onRejected(error, options) {
    const { message } = options
    const { response } = error
    if (response.status - 0 !== 200) {
      message.error('这会有点忙，喝杯茶再试试')
    }
    return Promise.reject(error)
  }
}
const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config, options) {
    // const { message } = options
    // const { url, xsrfCookieName } = config
    // if (url.indexOf('login') === -1 && xsrfCookieName && !Cookie.get(xsrfCookieName)) {
    //   message.warning('认证 token 已过期，请重新登录')
    // }
    return config
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options
    message.error(error.message)
    return Promise.reject(error)
  }
}
// 请求拦截器 全局loading处理
const reqLoading = {
  onFulfilled(config, options) {
    // 前端做一个半小时内无操作直接退出的逻辑
    computedTime()
    try {
      // 是否需要loading 把需要loading的url缓存下来 用于响应拦截判断
      const { loading = true } = config || {}
      if (loading) {
        showFullScreenLoading()
        // loadingRequest.push(config.url)
      }
      return config
    } catch (error) {
      console.log(error)
    }
  },
  onRejected(error, options) {
    console.log(error, options)
    return Promise.reject(error)
  }
}
// 响应拦截器 全局loading处理
const respLoading = {
  onFulfilled(response, options) {
    try {
      // const { url } = response.config
      // 更新拦截信息
      // loadingRequest.forEach((item, index) => {
      //   if (url === item) loadingRequest.splice(index, 1)
      // })
      // 需要拦截的请求完成 关闭loading
      // if (loadingRequest.length === 0) {
      // }
      tryHideFullScreenLoading()
      return response
    } catch (error) {
      console.log(error)
      tryHideFullScreenLoading()
    }
  },
  onRejected(error, options) {
    console.log(error, options)
    tryHideFullScreenLoading()
    return Promise.reject(error)
  }
}
/**
 * 全局添加请求参数验签
 * @param error 错误对象
 * @param options 应用配置 包含: {router, i18n, store, message}
 * @returns {Promise<never>}
 */
const signCheck = {
  onFulfilled(config, options) {
    const { method, data, params } = config
    const obj = {
      get: makeGetSign,
      post: makePostSign
    }
    const timestamp = new Date().getTime()
    const dataRes = method === 'get' ? params : JSON.stringify(data)
    const signature = obj[method](dataRes, timestamp)
    config.headers['signature'] = signature
    config.headers['timestamp'] = timestamp
    return config
  },
  onRejected(error, options) {
    console.log('验签校验失败')
    return Promise.reject(error)
  }
}

export default {
  request: [reqLoading, signCheck, reqCommon], // 请求拦截
  response: [respLoading, resp401, resp403, respCommon] // 响应拦截
}
