var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-row",
      class: {
        "wkflow-error-tip":
          !!_vm.record.options.baseInfoList[0].labelErrorMsg ||
          _vm.record.hasErrorValid,
      },
    },
    [
      _vm._l(_vm.record.options.baseInfoList, function (item, index) {
        return [
          _c(
            "div",
            { key: "baseInfoList" + index, staticClass: "baseInfoItemBox" },
            [
              _c(
                "div",
                { staticClass: "label", class: { require: item.isRequire } },
                [_vm._v(_vm._s(item.label))]
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(item.placeholder)),
                  ]),
                  item.placeholder.indexOf("请选择") > -1
                    ? _c("a-icon", { attrs: { type: "right" } })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }