var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "commonBox",
      class: {
        "wkflow-error-tip":
          !!_vm.record.options.baseInfoList[0].labelErrorMsg ||
          _vm.record.hasErrorValid,
      },
    },
    [
      _vm.record.type === "WKThreeLevel"
        ? [
            _vm._l(_vm.record.options.baseInfoList, function (item, index) {
              return [
                [0, 3].includes(index)
                  ? [
                      _c(
                        "div",
                        {
                          key: "baseInfoList" + index,
                          staticClass: "baseInfoItemBox",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "label",
                              class: { require: _vm.record.isRequire },
                            },
                            [_vm._v(_vm._s(item.label))]
                          ),
                          _c(
                            "div",
                            { staticClass: "right" },
                            [
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(item.placeholder)),
                              ]),
                              _vm.isSelect
                                ? _c("a-icon", { attrs: { type: "right" } })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ]
            }),
          ]
        : [
            _vm._l(_vm.record.options.baseInfoList, function (item, index) {
              return [
                _c(
                  "div",
                  {
                    key: "baseInfoList" + index,
                    staticClass: "baseInfoItemBox",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "label",
                        class: { require: _vm.record.isRequire },
                      },
                      [_vm._v(_vm._s(item.label))]
                    ),
                    _c(
                      "div",
                      { staticClass: "right" },
                      [
                        _c("div", { staticClass: "value" }, [
                          _vm._v(_vm._s(item.placeholder)),
                        ]),
                        _vm.isSelect
                          ? _c("a-icon", { attrs: { type: "right" } })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }