var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "option-change-container" },
    [
      _vm.type === "option" || _vm.type === "tab"
        ? _c(
            "a-row",
            { attrs: { gutter: 8 } },
            [
              _vm._l(_vm.value, function (val, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "option-change-box" },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 9 } },
                      [
                        _c("a-input", {
                          attrs: {
                            placeholder: "名称",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: val.label,
                            callback: function ($$v) {
                              _vm.$set(val, "label", $$v)
                            },
                            expression: "val.label",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 9 } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "值", disabled: _vm.disabled },
                          model: {
                            value: val.value,
                            callback: function ($$v) {
                              _vm.$set(val, "value", $$v)
                            },
                            expression: "val.value",
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.disabled
                      ? _c("a-col", { attrs: { span: 6 } }, [
                          _c(
                            "div",
                            {
                              staticClass: "option-delete-box",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(index)
                                },
                              },
                            },
                            [_c("a-icon", { attrs: { type: "delete" } })],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              !_vm.disabled
                ? _c("a-col", { attrs: { span: 24 } }, [
                    _c("a", { on: { click: _vm.handleAdd } }, [_vm._v("添加")]),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.type === "WKRadio"
        ? _c(
            "div",
            [
              _c(
                "a-radio-group",
                {
                  attrs: { disabled: _vm.disabled },
                  on: { change: _vm.selectDefault },
                  model: {
                    value: _vm.selectValue,
                    callback: function ($$v) {
                      _vm.selectValue = $$v
                    },
                    expression: "selectValue",
                  },
                },
                _vm._l(_vm.value, function (key, index) {
                  return _c(
                    "a-radio",
                    {
                      key: "radio" + index,
                      staticClass: "radioItem",
                      attrs: { value: index },
                    },
                    [
                      _c("a-input", {
                        staticClass: "int",
                        attrs: { "max-length": 50, placeholder: "选项名称" },
                        model: {
                          value: key.label,
                          callback: function ($$v) {
                            _vm.$set(key, "label", $$v)
                          },
                          expression: "key.label",
                        },
                      }),
                      _c("a-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.value.length > 1,
                            expression: "value.length > 1",
                          },
                        ],
                        staticClass: "delBtn",
                        attrs: { type: "delete" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(index)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              !_vm.disabled
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.value.length < _vm.maxLength,
                          expression: "value.length < maxLength",
                        },
                      ],
                    },
                    [
                      _c("a", { on: { click: _vm.handleAdd } }, [
                        _vm._v("添加选项"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.type === "WKCheckbox"
        ? _c(
            "div",
            [
              _c(
                "a-checkbox-group",
                {
                  attrs: { disabled: _vm.disabled },
                  on: { change: _vm.selectDefault },
                  model: {
                    value: _vm.selectValue,
                    callback: function ($$v) {
                      _vm.selectValue = $$v
                    },
                    expression: "selectValue",
                  },
                },
                _vm._l(_vm.value, function (key, index) {
                  return _c(
                    "a-checkbox",
                    {
                      key: "checkbox" + index,
                      staticClass: "radioItem",
                      attrs: { value: index },
                    },
                    [
                      _c("a-input", {
                        staticClass: "int",
                        attrs: { "max-length": 50, placeholder: "选项名称" },
                        model: {
                          value: key.label,
                          callback: function ($$v) {
                            _vm.$set(key, "label", $$v)
                          },
                          expression: "key.label",
                        },
                      }),
                      _c("a-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.value.length > 1,
                            expression: "value.length > 1",
                          },
                        ],
                        staticClass: "delBtn",
                        attrs: { type: "delete" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(index)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              !_vm.disabled
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.value.length < _vm.maxLength,
                          expression: "value.length < maxLength",
                        },
                      ],
                    },
                    [
                      _c("a", { on: { click: _vm.handleAdd } }, [
                        _vm._v("添加选项"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.type === "WKMultiSelect" || _vm.type === "WKSelect"
        ? _c(
            "div",
            [
              _c(
                "a-form-model-item",
                { staticClass: "radioItem", attrs: { label: "选项" } },
                _vm._l(_vm.value, function (key, index) {
                  return _c(
                    "a-form-model-item",
                    {
                      key: "select" + index,
                      attrs: {
                        label: "",
                        prop: "options." + index + ".label",
                        rules: {
                          required: true,
                          message: "选项不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("a-input", {
                        staticClass: "int",
                        attrs: {
                          "max-length": 50,
                          disabled: _vm.disabled,
                          placeholder: "选项名称",
                        },
                        model: {
                          value: key.label,
                          callback: function ($$v) {
                            _vm.$set(key, "label", $$v)
                          },
                          expression: "key.label",
                        },
                      }),
                      !_vm.disabled
                        ? _c("a-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.value.length > 1,
                                expression: "value.length > 1",
                              },
                            ],
                            staticClass: "delBtn",
                            attrs: { type: "delete" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
              !_vm.disabled
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.value.length < _vm.maxLength,
                          expression: "value.length < maxLength",
                        },
                      ],
                    },
                    [
                      _c("a", { on: { click: _vm.handleAdd } }, [
                        _vm._v("添加选项"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.type === "rules"
        ? _c(
            "a-row",
            { attrs: { gutter: 8 } },
            [
              _vm._l(_vm.value, function (val, index) {
                return _c("span", { key: index }, [
                  index !== 0
                    ? _c(
                        "div",
                        { staticClass: "option-change-box" },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 18 } },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "提示信息" },
                                model: {
                                  value: val.message,
                                  callback: function ($$v) {
                                    _vm.$set(val, "message", $$v)
                                  },
                                  expression: "val.message",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 18 } },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "正则表达式pattern" },
                                model: {
                                  value: val.pattern,
                                  callback: function ($$v) {
                                    _vm.$set(val, "pattern", $$v)
                                  },
                                  expression: "val.pattern",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("a-col", { attrs: { span: 6 } }, [
                            _c(
                              "div",
                              {
                                staticClass: "option-delete-box",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(index)
                                  },
                                },
                              },
                              [_c("a-icon", { attrs: { type: "delete" } })],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              }),
              _c("a-col", { attrs: { span: 24 } }, [
                _c("a", { on: { click: _vm.handleAddRules } }, [
                  _vm._v("增加校验"),
                ]),
              ]),
            ],
            2
          )
        : _vm.type === "colspan"
        ? _c(
            "a-row",
            { attrs: { gutter: 8 } },
            [
              _vm._l(_vm.value, function (val, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "option-change-box" },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 18 } },
                      [
                        _c("a-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { max: 24, placeholder: "名称" },
                          model: {
                            value: val.span,
                            callback: function ($$v) {
                              _vm.$set(val, "span", $$v)
                            },
                            expression: "val.span",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("a-col", { attrs: { span: 6 } }, [
                      _c(
                        "div",
                        {
                          staticClass: "option-delete-box",
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [_c("a-icon", { attrs: { type: "delete" } })],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              }),
              _c("a-col", { attrs: { span: 24 } }, [
                _c("a", { on: { click: _vm.handleAddCol } }, [_vm._v("添加")]),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }