var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "预览",
        visible: _vm.visible,
        "ok-text": "获取数据",
        "cancel-text": "关闭",
        "destroy-on-close": true,
        centered: true,
        "dialog-style": _vm.dialogStyle,
        "body-style": _vm.bodyStyle,
        width: _vm.previewWidth + "px",
        footer: null,
      },
      on: { ok: _vm.handleGetData, cancel: _vm.handleCancel },
    },
    [
      _c("div", { staticClass: "phone-panel" }, [
        _c(
          "div",
          { staticClass: "scroll-p" },
          [
            _c("k-form-build", {
              ref: "KFormBuild",
              attrs: { value: _vm.jsonData },
              on: { submit: _vm.handleSubmit },
            }),
          ],
          1
        ),
      ]),
      _c("jsonModel", { ref: "jsonModel" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }