var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "dynamicValidateForm",
      attrs: { layout: "inline", model: _vm.dynamicValidateForm },
    },
    [
      _c("a-table", {
        staticClass: "batch-table",
        attrs: {
          pagination: false,
          "row-key": function (record) {
            return record.key
          },
          columns: _vm.columns,
          "data-source": _vm.dynamicValidateForm.domains,
          bordered: "",
          scroll: {
            x:
              _vm.listLength * 190 +
              80 +
              (!_vm.record.options.hideSequence ? 60 : 0),
            y: _vm.record.options.scrollY,
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.record.list, function (item) {
              return {
                key: item.key,
                fn: function (text, recordItem, index) {
                  return [
                    _c("KFormModelItem", {
                      key: item.key + "1",
                      attrs: {
                        record: item,
                        config: _vm.config,
                        "parent-disabled": _vm.disabled,
                        index: index,
                        domains: _vm.dynamicValidateForm.domains,
                        "dynamic-data": _vm.dynamicData,
                      },
                      on: { input: _vm.handleInput },
                      model: {
                        value: recordItem[item.model],
                        callback: function ($$v) {
                          _vm.$set(recordItem, item.model, $$v)
                        },
                        expression: "recordItem[item.model]",
                      },
                    }),
                  ]
                },
              }
            }),
            {
              key: "dynamic-opr-button",
              fn: function (text, recordItem) {
                return [
                  !_vm.disabled
                    ? _c("a-icon", {
                        staticClass: "dynamic-opr-button",
                        attrs: { title: "删除该行", type: "minus-circle-o" },
                        on: {
                          click: function ($event) {
                            return _vm.removeDomain(recordItem)
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.disabled
                    ? _c("a-icon", {
                        staticClass: "dynamic-opr-button",
                        attrs: { title: "复制添加", type: "copy-o" },
                        on: {
                          click: function ($event) {
                            return _vm.copyDomain(recordItem)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-button",
        {
          attrs: { type: "dashed", disabled: _vm.disabled },
          on: { click: _vm.addDomain },
        },
        [_c("a-icon", { attrs: { type: "plus" } }), _vm._v("增加 ")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }