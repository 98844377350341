var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-config-provider",
    { attrs: { locale: _vm.locale } },
    [
      typeof _vm.value.list !== "undefined" &&
      typeof _vm.value.config !== "undefined"
        ? _c(
            "a-form",
            {
              staticClass: "k-form-build-9136076486841527",
              style: _vm.value.config.customStyle,
              attrs: {
                layout: _vm.value.config.layout,
                "hide-required-mark": _vm.value.config.hideRequiredMark,
                form: _vm.form,
              },
              on: { submit: _vm.handleSubmit },
            },
            _vm._l(_vm.value.list, function (record, index) {
              return _c("buildBlocks", {
                key: index,
                ref: "buildBlocks",
                refInFor: true,
                attrs: {
                  record: record,
                  "dynamic-data": _vm.getDynamicData,
                  config: _vm.config,
                  disabled: _vm.disabled,
                  "form-config": _vm.value.config,
                  "validator-error": _vm.validatorError,
                },
                on: { handleReset: _vm.reset, change: _vm.handleChange },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }