var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DictionariesInput", {
    attrs: { record: _vm.record, parameter: _vm.record.options.parameter },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v
      },
      expression: "data",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }