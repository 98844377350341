var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Shift", {
    attrs: { record: _vm.record },
    on: { getShiftValue: _vm.handleChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }