var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "msgbox-fade" } }, [
    _c("div", { staticClass: "CbReconfirm" }, [
      _c("div", { staticClass: "modal-mask" }),
      _c("div", { staticClass: "modal-wrap", on: { click: _vm.close } }, [
        _c(
          "div",
          {
            staticClass: "reconfirm-view",
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c("div", { staticClass: "reconfirm-header" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("a-icon", {
                    attrs: { type: "info-circle", theme: "filled" },
                  }),
                  _c("span", { staticClass: "title-name" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "close", on: { click: _vm.close } },
                [_c("a-icon", { attrs: { type: "close" } })],
                1
              ),
            ]),
            _c("div", {
              staticClass: "reconfirm-content",
              domProps: { innerHTML: _vm._s(_vm.content) },
            }),
            _c(
              "div",
              { staticClass: "reconfirm-footer" },
              [
                _c("a-button", { on: { click: _vm.close } }, [
                  _vm._v(" 取消 "),
                ]),
                _c(
                  "a-button",
                  {
                    staticClass: "footer-primary",
                    attrs: { type: "primary" },
                    on: { click: _vm.confirm },
                  },
                  [_vm._v(" 确认 ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }