// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView')
}

const routerMap = {
  globalSetting: {
    name: 'globalSetting',
    // subName: '系统设置',
    component: view.blank
  },
  globalSettingCompanyInfo: {
    name: 'globalSettingCompanyInfo',
    // subName: '公司信息',
    component: () => import('@/pages/globalSetting/globalSettingCompanyInfo')
  },
  globalSettingContract: {
    name: 'globalSettingContract',
    // subName: '合同主体',
    component: () => import('@/pages/globalSetting/globalSettingContract')
  },
  globalSettingLocation: {
    name: 'globalSettingLocation',
    // subName: '工作地点',
    component: () => import('@/pages/globalSetting/globalSettingLocation')
  },
  globalSettingAuthor: {
    name: 'globalSettingAuthor',
    // subName: '权限设置',
    component: () => import('@/pages/globalSetting/globalSettingAuthor')
  },
  globalSettingEquity: {
    name: 'globalSettingEquity',
    // subName: '权益中心',
    component: () => import('@/pages/globalSetting/globalSettingEquity')
  },
  globalSettingOperationDate: {
    name: 'globalSettingOperationDate',
    // subName: '操作日志',
    component: () => import('@/pages/globalSetting/globalSettingOperationDate')
  }
}

export default routerMap
