var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DepTreeSelect", {
    attrs: {
      placeholder: _vm.record.options.placeholder,
      disabled: _vm.record.options.disabled,
      multiple: _vm.record.options.multiple,
      "has-relation": _vm.hasRelation,
      "id-list": _vm.idList,
      "clear-post-val": _vm.clearPostVal,
      "clear-rank-val": _vm.clearRankVal,
      "disabled-rank": _vm.disabledRank,
      "disabled-post": _vm.disabledPost,
    },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v
      },
      expression: "data",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }