var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticStyle: { top: "20px" },
      attrs: {
        title: "代码",
        footer: null,
        visible: _vm.visible,
        "wrap-class-name": "code-modal-9136076486841527",
        width: "850px",
        "destroy-on-close": true,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-tabs",
        { staticStyle: { height: "100%" }, attrs: { "tab-position": "left" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "VUE" } },
            [
              _c("previewCode", {
                attrs: {
                  "editor-json": _vm.editorVueJson,
                  "file-format": "vue",
                },
              }),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "HTML" } },
            [
              _c("previewCode", {
                attrs: {
                  "editor-json": _vm.editorHtmlJson,
                  "file-format": "html",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }