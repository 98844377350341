var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-model",
    {
      ref: "dynamicValidateForm",
      staticClass: "select-input-list-box",
      attrs: { layout: "inline", model: _vm.dynamicValidateForm },
    },
    _vm._l(_vm.record.columns, function (column, i) {
      return _c(
        "div",
        { key: i },
        [
          _c(
            "a-form-model-item",
            [
              _vm.record.options.multiple
                ? _c(
                    "a-checkbox",
                    {
                      attrs: {
                        checked: _vm.dynamicValidateForm.domains[i].checked,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onCheckboxChange($event, i)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(column.label) + " ")]
                  )
                : _c(
                    "a-radio",
                    {
                      attrs: {
                        checked: _vm.dynamicValidateForm.domains[i].checked,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onRadioChange($event, i)
                        },
                      },
                    },
                    [_vm._v(_vm._s(column.label))]
                  ),
            ],
            1
          ),
          _vm._l(column.list, function (item, index) {
            return _c(
              "span",
              { key: index },
              [
                _c("KFormModelItem", {
                  key: item.key + "1",
                  attrs: {
                    record: item,
                    config: _vm.config,
                    "parent-disabled": _vm.disabled,
                    domains: _vm.dynamicValidateForm.domains[i],
                    "dynamic-data": _vm.dynamicData,
                  },
                  on: { input: _vm.handleInput },
                  model: {
                    value: _vm.dynamicValidateForm.domains[i][item.model],
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.dynamicValidateForm.domains[i],
                        item.model,
                        $$v
                      )
                    },
                    expression: "dynamicValidateForm.domains[i][item.model]",
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }