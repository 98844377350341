var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticStyle: { top: "20px" },
      attrs: {
        title: "JSON数据",
        footer: null,
        visible: _vm.visible,
        "destroy-on-close": true,
        "wrap-class-name": "code-modal-9136076486841527",
        width: "850px",
      },
      on: { cancel: _vm.handleCancel },
    },
    [_c("previewCode", { attrs: { "editor-json": _vm.editorJson } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }