var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.record.type === "date" &&
    _vm.record.options.format === "YYYY-MM" &&
    _vm.record.options.range === false
    ? _c("a-month-picker", {
        style: "width:" + _vm.record.options.width,
        attrs: {
          disabled: _vm.record.options.disabled || _vm.parentDisabled,
          "allow-clear": _vm.record.options.clearable,
          placeholder: _vm.record.options.placeholder,
          format: _vm.record.options.format,
          value: _vm.date,
          "get-calendar-container": function (triggerNode) {
            return triggerNode.parentNode
          },
        },
        on: { change: _vm.handleSelectChange },
      })
    : _vm.record.type === "date" && _vm.record.options.range === false
    ? _c("a-date-picker", {
        style: "width:" + _vm.record.options.width,
        attrs: {
          disabled: _vm.record.options.disabled || _vm.parentDisabled,
          "show-time": _vm.record.options.showTime,
          "allow-clear": _vm.record.options.clearable,
          placeholder: _vm.record.options.placeholder,
          format: _vm.record.options.format,
          "disabled-date": _vm.record.options.disabledDate || _vm.disabledDate,
          value: _vm.date,
          "get-calendar-container": function (triggerNode) {
            return triggerNode.parentNode
          },
        },
        on: { change: _vm.handleSelectChange },
      })
    : _vm.record.type === "date" && _vm.record.options.range === true
    ? _c("a-range-picker", {
        style: "width:" + _vm.record.options.width,
        attrs: {
          "show-time": _vm.record.options.showTime,
          disabled: _vm.record.options.disabled || _vm.parentDisabled,
          "allow-clear": _vm.record.options.clearable,
          placeholder: _vm.record.options.rangePlaceholder,
          format: _vm.record.options.format,
          value: _vm.date,
          "get-calendar-container": function (triggerNode) {
            return triggerNode.parentNode
          },
        },
        on: { change: _vm.handleSelectChange },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }