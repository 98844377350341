var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "properties-centent kk-checkbox" }, [
    _c(
      "div",
      { staticClass: "properties-body" },
      [
        _c("a-empty", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectItem.key === "",
              expression: "selectItem.key === ''",
            },
          ],
          staticClass: "hint-box",
          attrs: { description: "未选择控件" },
        }),
        _vm.type === "kForm"
          ? _c(
              "a-form",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectItem.key !== "",
                    expression: "selectItem.key !== ''",
                  },
                ],
              },
              [
                typeof _vm.selectItem.label !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "标签" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.selectItem.label,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "label", $$v)
                            },
                            expression: "selectItem.label",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.hideModel && typeof _vm.selectItem.model !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "数据字段" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.selectItem.model,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "model", $$v)
                            },
                            expression: "selectItem.model",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "input"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "输入框type" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "type", $$v)
                            },
                            expression: "options.type",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.rangePlaceholder !== "undefined" &&
                _vm.options.range
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "占位内容" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.rangePlaceholder[0],
                            callback: function ($$v) {
                              _vm.$set(_vm.options.rangePlaceholder, 0, $$v)
                            },
                            expression: "options.rangePlaceholder[0]",
                          },
                        }),
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.rangePlaceholder[1],
                            callback: function ($$v) {
                              _vm.$set(_vm.options.rangePlaceholder, 1, $$v)
                            },
                            expression: "options.rangePlaceholder[1]",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                ["selectDictionaries", "WKDictionary"].includes(
                  _vm.selectItem.type
                )
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "字典项type配置" } },
                      [
                        _c(
                          "a-select",
                          {
                            on: { change: _vm.dictionaryChange },
                            model: {
                              value: _vm.selectItem.options.parameter,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectItem.options,
                                  "parameter",
                                  $$v
                                )
                              },
                              expression: "selectItem.options.parameter",
                            },
                          },
                          _vm._l(_vm.dictionaryList, function (key, index) {
                            return _c(
                              "a-select-opt-group",
                              { key: "Dictionaries" + index },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [_vm._v(_vm._s(key.label))]
                                ),
                                _vm._l(key.value, function (item, listIndex) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: "listItem" + listIndex,
                                      attrs: { value: item.value },
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : typeof _vm.options.placeholder !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "占位内容" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.placeholder,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "placeholder", $$v)
                            },
                            expression: "options.placeholder",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "textarea"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "自适应内容高度" } },
                      [
                        _c("a-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "最小高度" },
                          model: {
                            value: _vm.options.minRows,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "minRows", $$v)
                            },
                            expression: "options.minRows",
                          },
                        }),
                        _c("a-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "最大高度" },
                          model: {
                            value: _vm.options.maxRows,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "maxRows", $$v)
                            },
                            expression: "options.maxRows",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.width !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "宽度" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.width,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "width", $$v)
                            },
                            expression: "options.width",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.height !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "高度" } },
                      [
                        _c("a-input-number", {
                          model: {
                            value: _vm.options.height,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "height", $$v)
                            },
                            expression: "options.height",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.step !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "步长" } },
                      [
                        _c("a-input-number", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.step,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "step", $$v)
                            },
                            expression: "options.step",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.min !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "最小值" } },
                      [
                        _c("a-input-number", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.min,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "min", $$v)
                            },
                            expression: "options.min",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.max !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "最大值" } },
                      [
                        _c("a-input-number", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.max,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "max", $$v)
                            },
                            expression: "options.max",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.maxLength !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "最大长度" } },
                      [
                        _c("a-input-number", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.maxLength,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "maxLength", $$v)
                            },
                            expression: "options.maxLength",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.tabBarGutter !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "标签间距" } },
                      [
                        _c("a-input-number", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.tabBarGutter,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "tabBarGutter", $$v)
                            },
                            expression: "options.tabBarGutter",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.precision !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "数值精度" } },
                      [
                        _c("a-input-number", {
                          attrs: { min: 0, max: 50, placeholder: "请输入" },
                          model: {
                            value: _vm.options.precision,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "precision", $$v)
                            },
                            expression: "options.precision",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.dictCode !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "dictCode" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.options.dictCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "dictCode", $$v)
                            },
                            expression: "options.dictCode",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.options !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "选项配置" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.dynamic,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "dynamic", $$v)
                              },
                              expression: "options.dynamic",
                            },
                          },
                          [
                            _c("a-radio-button", { attrs: { value: false } }, [
                              _vm._v("静态数据"),
                            ]),
                            _c("a-radio-button", { attrs: { value: true } }, [
                              _vm._v("动态数据"),
                            ]),
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.options.dynamic,
                              expression: "options.dynamic",
                            },
                          ],
                          attrs: { placeholder: "动态数据变量名" },
                          model: {
                            value: _vm.options.dynamicKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "dynamicKey", $$v)
                            },
                            expression: "options.dynamicKey",
                          },
                        }),
                        _c("KChangeOption", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.options.dynamic,
                              expression: "!options.dynamic",
                            },
                          ],
                          model: {
                            value: _vm.options.options,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "options", $$v)
                            },
                            expression: "options.options",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                ["tabs", "selectInputList"].includes(_vm.selectItem.type)
                  ? _c(
                      "a-form-item",
                      {
                        attrs: {
                          label:
                            _vm.selectItem.type === "tabs"
                              ? "页签配置"
                              : "列选项配置",
                        },
                      },
                      [
                        _c("KChangeOption", {
                          attrs: { type: "tab" },
                          model: {
                            value: _vm.selectItem.columns,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "columns", $$v)
                            },
                            expression: "selectItem.columns",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "grid"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "栅格间距" } },
                      [
                        _c("a-input-number", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.selectItem.options.gutter,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem.options, "gutter", $$v)
                            },
                            expression: "selectItem.options.gutter",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "grid"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "列配置项" } },
                      [
                        _c("KChangeOption", {
                          attrs: { type: "colspan" },
                          model: {
                            value: _vm.selectItem.columns,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "columns", $$v)
                            },
                            expression: "selectItem.columns",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "switch"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "默认值" } },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.options.defaultValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "defaultValue", $$v)
                            },
                            expression: "options.defaultValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                ["number", "slider"].includes(_vm.selectItem.type) >= 0
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "默认值" } },
                      [
                        _c("a-input-number", {
                          attrs: {
                            step: _vm.options.step,
                            min: _vm.options.min || -Infinity,
                            max: _vm.options.max || Infinity,
                          },
                          model: {
                            value: _vm.options.defaultValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "defaultValue", $$v)
                            },
                            expression: "options.defaultValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "rate"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "默认值" } },
                      [
                        _c("a-rate", {
                          attrs: {
                            "allow-half": _vm.options.allowHalf,
                            count: _vm.options.max,
                          },
                          model: {
                            value: _vm.options.defaultValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "defaultValue", $$v)
                            },
                            expression: "options.defaultValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "select"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "默认值" } },
                      [
                        _c("a-select", {
                          attrs: { options: _vm.options.options },
                          model: {
                            value: _vm.options.defaultValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "defaultValue", $$v)
                            },
                            expression: "options.defaultValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "radio"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "默认值" } },
                      [
                        _c("a-radio-group", {
                          attrs: { options: _vm.options.options },
                          model: {
                            value: _vm.options.defaultValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "defaultValue", $$v)
                            },
                            expression: "options.defaultValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "checkbox"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "默认值" } },
                      [
                        _c("a-checkbox-group", {
                          attrs: { options: _vm.options.options },
                          model: {
                            value: _vm.options.defaultValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "defaultValue", $$v)
                            },
                            expression: "options.defaultValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "date"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "默认值" } },
                      [
                        !_vm.options.range
                          ? _c("a-input", {
                              attrs: {
                                placeholder:
                                  typeof _vm.options.format === "undefined"
                                    ? ""
                                    : _vm.options.format,
                              },
                              model: {
                                value: _vm.options.defaultValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "defaultValue", $$v)
                                },
                                expression: "options.defaultValue",
                              },
                            })
                          : _vm._e(),
                        _vm.options.range
                          ? _c("a-input", {
                              attrs: {
                                placeholder:
                                  typeof _vm.options.format === "undefined"
                                    ? ""
                                    : _vm.options.format,
                              },
                              model: {
                                value: _vm.options.rangeDefaultValue[0],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.options.rangeDefaultValue,
                                    0,
                                    $$v
                                  )
                                },
                                expression: "options.rangeDefaultValue[0]",
                              },
                            })
                          : _vm._e(),
                        _vm.options.range
                          ? _c("a-input", {
                              attrs: {
                                placeholder:
                                  typeof _vm.options.format === "undefined"
                                    ? ""
                                    : _vm.options.format,
                              },
                              model: {
                                value: _vm.options.rangeDefaultValue[1],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.options.rangeDefaultValue,
                                    1,
                                    $$v
                                  )
                                },
                                expression: "options.rangeDefaultValue[1]",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                ![
                  "number",
                  "radio",
                  "checkbox",
                  "date",
                  "rate",
                  "select",
                  "switch",
                  "slider",
                  "html",
                ].includes(_vm.selectItem.type) &&
                typeof _vm.options.defaultValue !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "默认值" } },
                      [
                        _c("a-input", {
                          attrs: {
                            placeholder:
                              typeof _vm.options.format === "undefined"
                                ? "请输入"
                                : _vm.options.format,
                          },
                          model: {
                            value: _vm.options.defaultValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "defaultValue", $$v)
                            },
                            expression: "options.defaultValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "html"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "默认值" } },
                      [
                        _c("a-textarea", {
                          attrs: { "auto-size": { minRows: 4, maxRows: 8 } },
                          model: {
                            value: _vm.options.defaultValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "defaultValue", $$v)
                            },
                            expression: "options.defaultValue",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.format !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "时间格式" } },
                      [
                        _c("a-input", {
                          attrs: {
                            placeholder: "时间格式如：YYYY-MM-DD HH:mm:ss",
                          },
                          model: {
                            value: _vm.options.format,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "format", $$v)
                            },
                            expression: "options.format",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.orientation !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "标签位置" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.orientation,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "orientation", $$v)
                              },
                              expression: "options.orientation",
                            },
                          },
                          [
                            _c("a-radio-button", { attrs: { value: "left" } }, [
                              _vm._v("左"),
                            ]),
                            _c("a-radio-button", { attrs: { value: "" } }, [
                              _vm._v("居中"),
                            ]),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "right" } },
                              [_vm._v("右")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "tabs"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "页签位置" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.tabPosition,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "tabPosition", $$v)
                              },
                              expression: "options.tabPosition",
                            },
                          },
                          [
                            _c("a-radio", { attrs: { value: "top" } }, [
                              _vm._v("top"),
                            ]),
                            _c("a-radio", { attrs: { value: "right" } }, [
                              _vm._v("right"),
                            ]),
                            _c("a-radio", { attrs: { value: "bottom" } }, [
                              _vm._v("bottom"),
                            ]),
                            _c("a-radio", { attrs: { value: "left" } }, [
                              _vm._v("left"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "tabs"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "页签类型" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "type", $$v)
                              },
                              expression: "options.type",
                            },
                          },
                          [
                            _c("a-radio-button", { attrs: { value: "line" } }, [
                              _vm._v("line"),
                            ]),
                            _c("a-radio-button", { attrs: { value: "card" } }, [
                              _vm._v("card"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.size !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "大小" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.size,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "size", $$v)
                              },
                              expression: "options.size",
                            },
                          },
                          [
                            _c(
                              "a-radio-button",
                              { attrs: { value: "large" } },
                              [_vm._v("large")]
                            ),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "default" } },
                              [_vm._v("default")]
                            ),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "small" } },
                              [_vm._v("small")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "button"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "类型" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "type", $$v)
                              },
                              expression: "options.type",
                            },
                          },
                          [
                            _c("a-radio", { attrs: { value: "primary" } }, [
                              _vm._v("Primary"),
                            ]),
                            _c("a-radio", { attrs: { value: "default" } }, [
                              _vm._v("Default"),
                            ]),
                            _c("a-radio", { attrs: { value: "dashed" } }, [
                              _vm._v("Dashed"),
                            ]),
                            _c("a-radio", { attrs: { value: "danger" } }, [
                              _vm._v("Danger"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.downloadWay !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "下载方式" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.downloadWay,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "downloadWay", $$v)
                              },
                              expression: "options.downloadWay",
                            },
                          },
                          [
                            _c("a-radio-button", { attrs: { value: "a" } }, [
                              _vm._v("a标签"),
                            ]),
                            _c("a-radio-button", { attrs: { value: "ajax" } }, [
                              _vm._v("ajax"),
                            ]),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "dynamic" } },
                              [_vm._v("动态函数")]
                            ),
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.options.downloadWay === "dynamic",
                              expression: "options.downloadWay === 'dynamic'",
                            },
                          ],
                          attrs: { placeholder: "动态函数名" },
                          model: {
                            value: _vm.options.dynamicFun,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "dynamicFun", $$v)
                            },
                            expression: "options.dynamicFun",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "button"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "按钮操作" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.handle,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "handle", $$v)
                              },
                              expression: "options.handle",
                            },
                          },
                          [
                            _c(
                              "a-radio-button",
                              { attrs: { value: "submit" } },
                              [_vm._v("提交")]
                            ),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "reset" } },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "dynamic" } },
                              [_vm._v("动态函数")]
                            ),
                          ],
                          1
                        ),
                        _c("a-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.options.handle === "dynamic",
                              expression: "options.handle === 'dynamic'",
                            },
                          ],
                          attrs: { placeholder: "动态函数名" },
                          model: {
                            value: _vm.options.dynamicFun,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "dynamicFun", $$v)
                            },
                            expression: "options.dynamicFun",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "alert"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "辅助描述" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.options.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "description", $$v)
                            },
                            expression: "options.description",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "alert"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "类型" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "type", $$v)
                              },
                              expression: "options.type",
                            },
                          },
                          [
                            _c("a-radio", { attrs: { value: "success" } }, [
                              _vm._v("success"),
                            ]),
                            _c("a-radio", { attrs: { value: "info" } }, [
                              _vm._v("info"),
                            ]),
                            _c("a-radio", { attrs: { value: "warning" } }, [
                              _vm._v("warning"),
                            ]),
                            _c("a-radio", { attrs: { value: "error" } }, [
                              _vm._v("error"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "alert"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "操作属性" } },
                      [
                        _c("kCheckbox", {
                          attrs: { label: "显示图标" },
                          model: {
                            value: _vm.options.showIcon,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "showIcon", $$v)
                            },
                            expression: "options.showIcon",
                          },
                        }),
                        _c("kCheckbox", {
                          attrs: { label: "无边框" },
                          model: {
                            value: _vm.options.banner,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "banner", $$v)
                            },
                            expression: "options.banner",
                          },
                        }),
                        _c("kCheckbox", {
                          attrs: { label: "可关闭" },
                          model: {
                            value: _vm.options.closable,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "closable", $$v)
                            },
                            expression: "options.closable",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "uploadImg"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "样式" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.listType,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "listType", $$v)
                              },
                              expression: "options.listType",
                            },
                          },
                          [
                            _c("a-radio-button", { attrs: { value: "text" } }, [
                              _vm._v("text"),
                            ]),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "picture" } },
                              [_vm._v("picture")]
                            ),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "picture-card" } },
                              [_vm._v("card")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.limit !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "最大上传数量" } },
                      [
                        _c("a-input-number", {
                          attrs: { min: 1 },
                          model: {
                            value: _vm.options.limit,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "limit", $$v)
                            },
                            expression: "options.limit",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.scrollY !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "scrollY" } },
                      [
                        _c("a-input-number", {
                          attrs: { min: 0 },
                          model: {
                            value: _vm.options.scrollY,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "scrollY", $$v)
                            },
                            expression: "options.scrollY",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.action !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "上传地址" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.action,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "action", $$v)
                            },
                            expression: "options.action",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.fileName !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "文件name" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.fileName,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "fileName", $$v)
                            },
                            expression: "options.fileName",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.data !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "额外参数（JSON格式）" } },
                      [
                        _c("a-textarea", {
                          attrs: { placeholder: "严格JSON格式" },
                          model: {
                            value: _vm.options.data,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "data", $$v)
                            },
                            expression: "options.data",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "text"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "文字对齐方式" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            attrs: { "button-style": "solid" },
                            model: {
                              value: _vm.options.textAlign,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "textAlign", $$v)
                              },
                              expression: "options.textAlign",
                            },
                          },
                          [
                            _c("a-radio-button", { attrs: { value: "left" } }, [
                              _vm._v("左"),
                            ]),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "center" } },
                              [_vm._v("居中")]
                            ),
                            _c(
                              "a-radio-button",
                              { attrs: { value: "right" } },
                              [_vm._v("右")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "text"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "字体属性设置" } },
                      [
                        _c("colorPicker", {
                          model: {
                            value: _vm.options.color,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "color", $$v)
                            },
                            expression: "options.color",
                          },
                        }),
                        _c("a-select", {
                          staticStyle: {
                            width: "36%",
                            "margin-left": "2%",
                            "vertical-align": "bottom",
                          },
                          attrs: { options: _vm.familyOptions },
                          model: {
                            value: _vm.options.fontFamily,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "fontFamily", $$v)
                            },
                            expression: "options.fontFamily",
                          },
                        }),
                        _c("a-select", {
                          staticStyle: {
                            width: "35%",
                            "margin-left": "2%",
                            "vertical-align": "bottom",
                          },
                          attrs: { options: _vm.sizeOptions },
                          model: {
                            value: _vm.options.fontSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "fontSize", $$v)
                            },
                            expression: "options.fontSize",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "text"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "操作属性" } },
                      [
                        _c("kCheckbox", {
                          attrs: { label: "显示必选标记" },
                          model: {
                            value: _vm.options.showRequiredMark,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "showRequiredMark", $$v)
                            },
                            expression: "options.showRequiredMark",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.hidden !== "undefined" ||
                typeof _vm.options.disabled !== "undefined" ||
                typeof _vm.options.readonly !== "undefined" ||
                typeof _vm.options.clearable !== "undefined" ||
                typeof _vm.options.multiple !== "undefined" ||
                typeof _vm.options.range !== "undefined" ||
                typeof _vm.options.showTime !== "undefined" ||
                typeof _vm.options.allowHalf !== "undefined" ||
                typeof _vm.options.showInput !== "undefined" ||
                typeof _vm.options.animated !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "操作属性" } },
                      [
                        typeof _vm.options.hidden !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "隐藏" },
                              model: {
                                value: _vm.options.hidden,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "hidden", $$v)
                                },
                                expression: "options.hidden",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.disabled !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "禁用" },
                              model: {
                                value: _vm.options.disabled,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "disabled", $$v)
                                },
                                expression: "options.disabled",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.readonly !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "只读" },
                              model: {
                                value: _vm.options.readonly,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "readonly", $$v)
                                },
                                expression: "options.readonly",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.clearable !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "可清除" },
                              model: {
                                value: _vm.options.clearable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "clearable", $$v)
                                },
                                expression: "options.clearable",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.multiple !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "多选" },
                              model: {
                                value: _vm.options.multiple,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "multiple", $$v)
                                },
                                expression: "options.multiple",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.range !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "范围选择" },
                              model: {
                                value: _vm.options.range,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "range", $$v)
                                },
                                expression: "options.range",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.showTime !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "时间选择器" },
                              model: {
                                value: _vm.options.showTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "showTime", $$v)
                                },
                                expression: "options.showTime",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.allowHalf !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "允许半选" },
                              model: {
                                value: _vm.options.allowHalf,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "allowHalf", $$v)
                                },
                                expression: "options.allowHalf",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.showInput !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "显示输入框" },
                              model: {
                                value: _vm.options.showInput,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "showInput", $$v)
                                },
                                expression: "options.showInput",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.showLabel !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "显示Label" },
                              model: {
                                value: _vm.options.showLabel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "showLabel", $$v)
                                },
                                expression: "options.showLabel",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.chinesization !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "汉化" },
                              model: {
                                value: _vm.options.chinesization,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "chinesization", $$v)
                                },
                                expression: "options.chinesization",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.hideSequence !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "隐藏序号" },
                              model: {
                                value: _vm.options.hideSequence,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "hideSequence", $$v)
                                },
                                expression: "options.hideSequence",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.drag !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "允许拖拽" },
                              model: {
                                value: _vm.options.drag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "drag", $$v)
                                },
                                expression: "options.drag",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.showSearch !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "可搜索" },
                              model: {
                                value: _vm.options.showSearch,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "showSearch", $$v)
                                },
                                expression: "options.showSearch",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.treeCheckable !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "可勾选" },
                              model: {
                                value: _vm.options.treeCheckable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "treeCheckable", $$v)
                                },
                                expression: "options.treeCheckable",
                              },
                            })
                          : _vm._e(),
                        typeof _vm.options.animated !== "undefined"
                          ? _c("kCheckbox", {
                              attrs: { label: "动画切换" },
                              model: {
                                value: _vm.options.animated,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "animated", $$v)
                                },
                                expression: "options.animated",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.selectItem.rules !== "undefined" &&
                _vm.selectItem.rules.length > 0
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "校验" } },
                      [
                        _c("kCheckbox", {
                          attrs: { label: "必填" },
                          model: {
                            value: _vm.selectItem.rules[0].required,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem.rules[0], "required", $$v)
                            },
                            expression: "selectItem.rules[0].required",
                          },
                        }),
                        _c("a-input", {
                          attrs: { placeholder: "必填校验提示信息" },
                          model: {
                            value: _vm.selectItem.rules[0].message,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem.rules[0], "message", $$v)
                            },
                            expression: "selectItem.rules[0].message",
                          },
                        }),
                        _c("KChangeOption", {
                          attrs: { type: "rules" },
                          model: {
                            value: _vm.selectItem.rules,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "rules", $$v)
                            },
                            expression: "selectItem.rules",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "table"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "表格样式CSS" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.selectItem.options.customStyle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectItem.options,
                                "customStyle",
                                $$v
                              )
                            },
                            expression: "selectItem.options.customStyle",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "table"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "属性" } },
                      [
                        _c("kCheckbox", {
                          attrs: { label: "显示边框" },
                          model: {
                            value: _vm.selectItem.options.bordered,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem.options, "bordered", $$v)
                            },
                            expression: "selectItem.options.bordered",
                          },
                        }),
                        _c("kCheckbox", {
                          attrs: { label: "鼠标经过点亮" },
                          model: {
                            value: _vm.selectItem.options.bright,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem.options, "bright", $$v)
                            },
                            expression: "selectItem.options.bright",
                          },
                        }),
                        _c("kCheckbox", {
                          attrs: { label: "紧凑型" },
                          model: {
                            value: _vm.selectItem.options.small,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem.options, "small", $$v)
                            },
                            expression: "selectItem.options.small",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "table"
                  ? _c("a-form-item", { attrs: { label: "提示" } }, [
                      _c("p", { staticStyle: { "line-height": "26px" } }, [
                        _vm._v("请点击右键增加行列，或者合并单元格"),
                      ]),
                    ])
                  : _vm._e(),
                typeof _vm.selectItem.help !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "帮助信息" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.selectItem.help,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "help", $$v)
                            },
                            expression: "selectItem.help",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.addonBefore !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "前缀" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.addonBefore,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "addonBefore", $$v)
                            },
                            expression: "options.addonBefore",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                typeof _vm.options.addonAfter !== "undefined"
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "后缀" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.options.addonAfter,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "addonAfter", $$v)
                            },
                            expression: "options.addonAfter",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _c(
              "a-form-model",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectItem.key !== "",
                    expression: "selectItem.key !== ''",
                  },
                ],
                ref: "ruleForm",
                attrs: {
                  model: _vm.options,
                  layout: "inline",
                  "label-col": { style: "width: 140px" },
                },
              },
              [
                _vm._l(_vm.options.baseInfoList, function (key, index) {
                  return [
                    (!["WKText", "WKThreeLevel"].includes(
                      _vm.selectItem.type
                    ) ||
                      (["WKThreeLevel"].includes(_vm.selectItem.type) &&
                        index == 0)) &&
                    !key.hide
                      ? _c(
                          "a-form-model-item",
                          {
                            key: "formItemBaseInfoVal" + index,
                            attrs: {
                              label:
                                "名称" +
                                (_vm.options.baseInfoList.length > 1
                                  ? index + 1
                                  : ""),
                              required: true,
                              prop: "baseInfoList." + index + ".label",
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "名称" +
                                    (_vm.options.baseInfoList.length > 1
                                      ? index + 1
                                      : "") +
                                    "不能为空",
                                  whitespace: true,
                                  trigger: ["blur", "change"],
                                },
                                {
                                  validator: function (rule, value, callback) {
                                    if (!!key.labelErrorMsg) {
                                      callback("" + key.labelErrorMsg)
                                    } else {
                                      callback()
                                    }
                                  },
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: {
                                "max-length": key.labelMaxLength,
                                disabled: key.labelDisable,
                                placeholder: "请输入",
                              },
                              model: {
                                value: key.label,
                                callback: function ($$v) {
                                  _vm.$set(
                                    key,
                                    "label",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "key.label",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    ![
                      "WKText",
                      "WKRadio",
                      "WKCheckbox",
                      "WKAutoCompute",
                      "WKThreeLevel",
                    ].includes(_vm.selectItem.type) ||
                    (["WKThreeLevel"].includes(_vm.selectItem.type) &&
                      index == 0) ||
                    (["WKThreeLevel"].includes(_vm.selectItem.type) &&
                      index == 1 &&
                      key.show)
                      ? _c(
                          "a-form-model-item",
                          {
                            key: "formItemBaseInfoLabel" + index,
                            attrs: {
                              label:
                                "提示文字" +
                                (_vm.options.baseInfoList.length > 1
                                  ? index + 1
                                  : ""),
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: {
                                "max-length": key.placeholderMaxLength,
                                disabled: key.placeholderDisable,
                                placeholder: "请输入",
                              },
                              model: {
                                value: key.placeholder,
                                callback: function ($$v) {
                                  _vm.$set(
                                    key,
                                    "placeholder",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "key.placeholder",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
                _vm.isDev &&
                typeof _vm.selectItem.model !== "undefined" &&
                !_vm.typeList.includes(_vm.selectItem.type)
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "数据字段" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.selectItem.model,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "model", $$v)
                            },
                            expression: "selectItem.model",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                ["WKText"].includes(_vm.selectItem.type)
                  ? [
                      _c(
                        "a-form-model-item",
                        {
                          key: "formItemBaseInfoLabel",
                          attrs: { label: "提示文字" },
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              "max-length":
                                _vm.options.baseInfoList[0]
                                  .placeholderMaxLength,
                              placeholder: "请输入",
                              "auto-size": { minRows: 3, maxRows: 5 },
                            },
                            model: {
                              value: _vm.options.baseInfoList[0].placeholder,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.options.baseInfoList[0],
                                  "placeholder",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "options.baseInfoList[0].placeholder",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                ["WKDate", "WKDateRange"].includes(_vm.selectItem.type)
                  ? [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "日期类型" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              on: { change: _vm.dataTypeChange },
                              model: {
                                value: _vm.selectItem.options.dataType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectItem.options,
                                    "dataType",
                                    $$v
                                  )
                                },
                                expression: "selectItem.options.dataType",
                              },
                            },
                            [
                              _c(
                                "a-radio",
                                {
                                  style: { display: "block" },
                                  attrs: { value: 0 },
                                },
                                [_vm._v(" 年-月-日 ")]
                              ),
                              _c(
                                "a-radio",
                                {
                                  style: { display: "block" },
                                  attrs: { value: 1 },
                                },
                                [_vm._v(" 年-月-日 时:分 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                ["selectDictionaries", "WKDictionary"].includes(
                  _vm.selectItem.type
                )
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "字典项type配置" } },
                      [
                        _c(
                          "a-select",
                          {
                            on: { change: _vm.dictionaryChange },
                            model: {
                              value: _vm.selectItem.options.parameter,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectItem.options,
                                  "parameter",
                                  $$v
                                )
                              },
                              expression: "selectItem.options.parameter",
                            },
                          },
                          _vm._l(_vm.dictionaryList, function (key, index) {
                            return _c(
                              "a-select-opt-group",
                              { key: "Dictionaries" + index },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "label" }, slot: "label" },
                                  [_vm._v(_vm._s(key.label))]
                                ),
                                _vm._l(key.value, function (item, listIndex) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: "listItem" + listIndex,
                                      attrs: { value: item.value },
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                ["WKPersonInfo"].includes(_vm.selectItem.type)
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "选项type配置" } },
                      [
                        _c(
                          "a-select",
                          {
                            on: { change: _vm.personInfoChange },
                            model: {
                              value: _vm.selectItem.model,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectItem, "model", $$v)
                              },
                              expression: "selectItem.model",
                            },
                          },
                          _vm._l(
                            _vm.personInfoList,
                            function (item, listIndex) {
                              return _c(
                                "a-select-option",
                                {
                                  key: "listItem" + listIndex,
                                  attrs: { value: item.value },
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                [
                  "WKDept",
                  "WKEmployee",
                  "WKDictionary",
                  "WKUploadFile",
                  "WKUploadImg",
                ].includes(_vm.selectItem.type)
                  ? [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "多选" } },
                        [
                          _c("a-switch", {
                            model: {
                              value: _vm.selectItem.options.multiple,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectItem.options,
                                  "multiple",
                                  $$v
                                )
                              },
                              expression: "selectItem.options.multiple",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                ["WKNumber", "WKAmount"].includes(_vm.selectItem.type)
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "单位" } },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: { placeholder: "请选择单位" },
                            model: {
                              value: _vm.selectItem.options.companyVal,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectItem.options,
                                  "companyVal",
                                  $$v
                                )
                              },
                              expression: "selectItem.options.companyVal",
                            },
                          },
                          _vm._l(
                            _vm.selectItem.options.companyList,
                            function (key) {
                              return _c("a-select-option", { key: key }, [
                                _vm._v(" " + _vm._s(key) + " "),
                              ])
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                ["WKAutoCompute"].includes(_vm.selectItem.type)
                  ? _c("a-form-model-item", { attrs: { label: "公式" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-autocompute",
                          on: {
                            click: function ($event) {
                              _vm.selectItem.options.visible = true
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v("计算公式=")]),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.validArgs) },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
                ["WKAmount", "WKAutoCompute", "WKNumber"].includes(
                  _vm.selectItem.type
                )
                  ? [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "格式" } },
                        [
                          _c(
                            "a-checkbox-group",
                            {
                              model: {
                                value: _vm.options.ruleType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.options, "ruleType", $$v)
                                },
                                expression: "options.ruleType",
                              },
                            },
                            [
                              !["WKNumber"].includes(_vm.selectItem.type)
                                ? _c("a-checkbox", { attrs: { value: 0 } }, [
                                    _vm._v(" 显示大写数字 "),
                                  ])
                                : _vm._e(),
                              !["WKNumber"].includes(_vm.selectItem.type)
                                ? _c("a-checkbox", { attrs: { value: 1 } }, [
                                    _vm._v(" 显示千分位分隔符 "),
                                  ])
                                : _vm._e(),
                              _c(
                                "a-checkbox",
                                { attrs: { value: 2 } },
                                [
                                  _vm._v(" 显示 "),
                                  _c("a-input", {
                                    staticStyle: { width: "40px" },
                                    attrs: {
                                      size: "small",
                                      disabled:
                                        !_vm.options.ruleType.includes(2),
                                    },
                                    on: {
                                      change: function (e) {
                                        return (_vm.options.floatNum =
                                          e.target.value > 2
                                            ? 2
                                            : e.target.value < 0
                                            ? 0
                                            : e.target.value)
                                      },
                                    },
                                    model: {
                                      value: _vm.options.floatNum,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.options, "floatNum", $$v)
                                      },
                                      expression: "options.floatNum",
                                    },
                                  }),
                                  _vm._v("位小数位数 "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _vm.options.options && _vm.options.options.length > 0
                  ? _c("KChangeOption", {
                      attrs: {
                        "max-length": _vm.options.optionLength,
                        type: _vm.selectItem.type,
                      },
                      model: {
                        value: _vm.options.options,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "options", $$v)
                        },
                        expression: "options.options",
                      },
                    })
                  : _vm._e(),
                _vm.selectItem.showSummari
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "是否汇总" } },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.options.summari,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "summari", $$v)
                            },
                            expression: "options.summari",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isDev && !_vm.typeList.includes(_vm.selectItem.type)
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "是否为摘要字段" } },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.selectItem.isSummary,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "isSummary", $$v)
                            },
                            expression: "selectItem.isSummary",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectItem.type === "WKDateRange"
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "是否自动计算时长" } },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.selectItem.autoCalc,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "autoCalc", $$v)
                            },
                            expression: "selectItem.autoCalc",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !["WKText", "WKAutoCompute"].includes(_vm.selectItem.type) &&
                !_vm.typeList.includes(_vm.selectItem.type)
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "是否必填" } },
                      [
                        _c("a-switch", {
                          attrs: { disabled: _vm.selectItem.requireDisable },
                          model: {
                            value: _vm.selectItem.isRequire,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "isRequire", $$v)
                            },
                            expression: "selectItem.isRequire",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.typeList.includes(_vm.selectItem.type)
                  ? _c(
                      "a-form-item",
                      { attrs: { label: "是否打印" } },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.selectItem.isPrint,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "isPrint", $$v)
                            },
                            expression: "selectItem.isPrint",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                ["WKThreeLevel"].includes(_vm.selectItem.type)
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "详细地址是否展示" } },
                      [
                        _c("a-switch", {
                          model: {
                            value: _vm.options.baseInfoList[1].show,
                            callback: function ($$v) {
                              _vm.$set(_vm.options.baseInfoList[1], "show", $$v)
                            },
                            expression: "options.baseInfoList[1].show",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                ["WKThreeLevel"].includes(_vm.selectItem.type) &&
                _vm.options.baseInfoList[1].show
                  ? _c(
                      "a-form-model-item",
                      { attrs: { label: "详细地址是否必填" } },
                      [
                        _c("a-switch", {
                          attrs: {
                            disabled: _vm.selectItem.addrRequireDisable,
                          },
                          model: {
                            value: _vm.selectItem.isAddrRequire,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectItem, "isAddrRequire", $$v)
                            },
                            expression: "selectItem.isAddrRequire",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }