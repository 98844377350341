var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-config-provider", { attrs: { locale: _vm.locale } }, [
    _c(
      "div",
      { staticClass: "form-designer-container-9136076486841527" },
      [
        _vm.toolbarsTop
          ? _c(
              "operatingArea",
              {
                attrs: {
                  "show-toolbars-text": _vm.showToolbarsText,
                  toolbars: _vm.toolbars,
                  "record-list": _vm.recordList,
                  "redo-list": _vm.redoList,
                },
                on: {
                  handleSave: _vm.handleSave,
                  handlePreview: _vm.handlePreview,
                  handleOpenImportJsonModal: _vm.handleOpenImportJsonModal,
                  handleOpenCodeModal: _vm.handleOpenCodeModal,
                  handleOpenJsonModal: _vm.handleOpenJsonModal,
                  handleReset: _vm.handleReset,
                  handleClose: _vm.handleClose,
                  handleUndo: _vm.handleUndo,
                  handleRedo: _vm.handleRedo,
                },
              },
              [
                _c(
                  "template",
                  { slot: "left-action" },
                  [_vm._t("left-action")],
                  2
                ),
                _c(
                  "template",
                  { slot: "right-action" },
                  [_vm._t("right-action")],
                  2
                ),
              ],
              2
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "content",
            class: {
              "show-head": _vm.showHead,
              "toolbars-top": _vm.toolbarsTop,
              "show-head-and-toolbars-top": _vm.toolbarsTop && _vm.showHead,
            },
          },
          [
            _c(
              "aside",
              { staticClass: "left" },
              [
                _vm.type === "kForm"
                  ? _c(
                      "a-collapse",
                      {
                        attrs: {
                          "default-active-key": _vm.collapseDefaultActiveKey,
                        },
                        on: { change: _vm.collapseChange },
                      },
                      [
                        _vm.basicsArray.length > 0 && _vm.type === "kForm"
                          ? _c(
                              "a-collapse-panel",
                              { key: "1", attrs: { header: "基础控件" } },
                              [
                                _c("collapseItem", {
                                  attrs: { list: _vm.basicsArray },
                                  on: {
                                    generateKey: _vm.generateKey,
                                    handleListPush: _vm.handleListPush,
                                    start: _vm.handleStart,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customComponentsList.length > 0
                          ? _c(
                              "a-collapse-panel",
                              {
                                key: "3",
                                attrs: { header: _vm.customComponents.title },
                              },
                              [
                                _c("collapseItem", {
                                  attrs: { list: _vm.customComponentsList },
                                  on: {
                                    generateKey: _vm.generateKey,
                                    handleListPush: _vm.handleListPush,
                                    start: _vm.handleStart,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.layoutArray.length > 0 && _vm.type === "kForm"
                          ? _c(
                              "a-collapse-panel",
                              { key: "4", attrs: { header: "布局控件" } },
                              [
                                _c("collapseItem", {
                                  attrs: { list: _vm.layoutArray },
                                  on: {
                                    generateKey: _vm.generateKey,
                                    handleListPush: _vm.handleListPush,
                                    start: _vm.handleStart,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : [
                      _c(
                        "div",
                        { staticClass: "form-design-oprations" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("基础字段"),
                          ]),
                          _c("div", { staticClass: "tipe" }, [
                            _c("svg", { staticClass: "iconpark-icon" }, [
                              _c("use", { attrs: { href: "#add-subset" } }),
                            ]),
                            _vm._v(" 鼠标向右拖拽字段添加到表单编辑区域 "),
                          ]),
                          _c("collapseItem", {
                            attrs: { list: _vm.customComponentsList },
                            on: {
                              generateKey: _vm.generateKey,
                              handleListPush: _vm.handleListPush,
                              start: _vm.handleStart,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
            _c(
              "section",
              { staticClass: "sect" },
              [
                !_vm.toolbarsTop
                  ? _c(
                      "operatingArea",
                      {
                        attrs: {
                          "show-toolbars-text": _vm.showToolbarsText,
                          toolbars: _vm.toolbars,
                          "record-list": _vm.recordList,
                          "redo-list": _vm.redoList,
                        },
                        on: {
                          handleSave: _vm.handleSave,
                          handlePreview: _vm.handlePreview,
                          handleOpenImportJsonModal:
                            _vm.handleOpenImportJsonModal,
                          handleOpenCodeModal: _vm.handleOpenCodeModal,
                          handleOpenJsonModal: _vm.handleOpenJsonModal,
                          handleReset: _vm.handleReset,
                          handleClose: _vm.handleClose,
                          handleUndo: _vm.handleUndo,
                          handleRedo: _vm.handleRedo,
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "left-action" },
                          [_vm._t("left-action")],
                          2
                        ),
                        _c(
                          "template",
                          { slot: "right-action" },
                          [_vm._t("right-action")],
                          2
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _c("k-form-component-panel", {
                  ref: "KFCP",
                  class: { "no-toolbars-top": !_vm.toolbarsTop },
                  attrs: {
                    "flow-setting": _vm.flowSetting,
                    data: _vm.data,
                    type: _vm.type,
                    "select-item": _vm.selectItem,
                    "no-model": _vm.noModel,
                    "hide-model": _vm.hideModel,
                    "start-type": _vm.startType,
                    "process-data": _vm.processData,
                  },
                  on: { handleSetSelectItem: _vm.handleSetSelectItem },
                }),
                _c("k-json-modal", { ref: "jsonModal" }),
                _c("k-code-modal", { ref: "codeModal" }),
              ],
              1
            ),
            _c(
              "aside",
              { staticClass: "right" },
              [
                _c(
                  "a-tabs",
                  {
                    attrs: {
                      "active-key": _vm.activeKey,
                      "tab-bar-style": { margin: 0 },
                    },
                    on: { change: _vm.changeTab },
                  },
                  [
                    _vm.type == "kForm"
                      ? _c(
                          "a-tab-pane",
                          { key: 1, attrs: { tab: "表单属性设置" } },
                          [
                            _c("formProperties", {
                              attrs: {
                                config: _vm.data.config,
                                "preview-options": _vm.previewOptions,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-tab-pane",
                      { key: 2, attrs: { tab: "控件属性设置" } },
                      [
                        _c("formItemProperties", {
                          ref: "formItemProperties",
                          staticClass: "form-item-properties",
                          attrs: {
                            "select-item": _vm.selectItem,
                            data: _vm.data,
                            "hide-model": _vm.hideModel,
                            type: _vm.type,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }