var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Rank", {
    attrs: {
      record: _vm.record,
      "has-relation": _vm.hasRelation,
      "post-id": _vm.idList.postId,
    },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v
      },
      expression: "data",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }